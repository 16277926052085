import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { BASE_URL } from './interceptor-context';
import { environment } from 'src/environments/environment';

@Injectable()
export class BaseUrlInterceptor implements HttpInterceptor {
  /**
   * Intercept the outgoing http request and add the base url to the request,
   * excluding any requests that fails the isIgnoreUrl check.
   * @param request the outgoing http request
   * @param next the next http handler, typically is another interceptor or the final HttpBackend
   * @returns an observable wrapping the http event from the next http handler
   */
  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    if (this.isIgnoreUrl(request.url)) {
      return next.handle(request);
    }
    const baseUrl = request.context.get(BASE_URL);
    // Adding baseurl to the api
    request = request.clone({
      url: `${baseUrl}${request.url}`,
    });
    return next.handle(request);
  }

  /**
   * Ignore any http requests that include assets in the url
   * @param url the url of the http request
   * @returns true if the url includes assets, false otherwise
   */
  isIgnoreUrl(url: string): boolean {
    return (
      url.includes('assets/') ||
      url.includes(environment.TRACEABILITY_API_BASE_URL) ||
      url.includes(environment.CRD_API)
    );
  }
}
