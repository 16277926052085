import Format from '@date-format';

export const DATE_FORMAT = Object.freeze({
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: Format.dateFormat,
    monthYearLabel: Format.monthFormat,
    dateA11yLabel: 'LL',
    monthYearA11yLabel: Format.monthFormat,
  },
});
