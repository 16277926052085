import { Directive } from '@angular/core';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { DATE_FORMAT } from '../constants/date.const';

@Directive({
  standalone: true,
  selector: '[appDateFormat]',
  providers: [
    {
      provide: MAT_DATE_FORMATS,
      useValue: DATE_FORMAT,
    },
  ],
})
export class DateFormatDirective {}
