import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { StorageService } from '../services/storage.service';
import { ThemeService } from '../services/theme.service';
import { AppStorage } from '../utils/storage-constants';

export const initializeGuard: CanActivateFn = () => {
  const storageService = inject(StorageService);
  const themeService = inject(ThemeService);
  const router = inject(Router);

  if (storageService.check(AppStorage.Authorization)) {
    return true;
  } else {
    router.navigate([themeService.getThemeLogoutLink()]);
  }
};
