import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { StorageService } from './storage.service';
import { AppStorage } from '../utils/storage-constants';

export interface Theme {
  headerLogo: string;
  headerLogoWidth: {
    // to ADD
    lg: string;
  };
  fullLogo: string;
  fullLogoWidth: {
    // to ADD
    lg: string;
  };
  env?: string;
  locale: {
    defaultLanguage: string;
  };
  links: {
    primary: string;
    contactUs: string; // to ADD
    social: {
      linkedin: string;
      youtube: string;
    };
  };
  emails: {
    contactUs: string;
  };
  auth: {
    tenantId: string;
    orgName: string;
    name: string;
    orgIdentifier: string;
    ag_org_id: number;
  };
  style: {
    tagline: string;
    infographic: string;

    colors: {
      headerBackground: string;
      headerText: string;
      // bodyBackground: string;
      primaryColor: string;
      primaryBtnTextColor: string;
      // primaryTextColor: string;
      secondaryColor: string;
      // secondaryBtnTextColor: string;
      // tablePrimaryBackground: string;
      tableSecondaryBackground: string;
      // accentColor1: string;
      // accentColor2: string;
      // accentColor3: string;
      // accentColor4: string;
      // accentColor5: string;
      // accentColor6: string;
    };
  };
}

export const defaultTheme = {
  headerLogo: '/assets/images/logo-white.svg',
  headerLogoWidth: {
    lg: '64px',
  },
  fullLogo: '/assets/images/arp-logo-full.svg',
  fullLogoWidth: {
    lg: '300px',
  },
  env: environment.name,
  locale: {
    defaultLanguage: 'engb',
  },
  links: {
    primary: 'https://rubber.agridence.com',
    contactUs: 'https://rubber.agridence.com/contact/#contact-form',
    social: {
      linkedin: 'https://www.linkedin.com/company/heveaconnect',
      youtube: 'https://www.youtube.com/channel/UCmETv7EQ-cNNrMZghj4yojg',
    },
  },
  emails: {
    contactUs: 'cs@agridence.com',
  },
  auth: {
    tenantId: 'agridence',
    orgName: 'agridence',
    name: 'Agridence',
    orgIdentifier: 'agridence',
    ag_org_id: 111,
  },
  style: {
    tagline: 'LOGIN.TRADE_UP_TO_DIGITAL_WORLD',
    infographic: '/assets/images/login-bg.svg',

    colors: {
      headerBackground: '#1446ce',
      headerText: '#fff',
      // bodyBackground: string;
      primaryColor: '#1446ce',
      primaryBtnTextColor: '#fff',
      // primaryTextColor: string;
      secondaryColor: '#95ace9',
      // secondaryBtnTextColor: string;
      // tablePrimaryBackground: string;
      tableSecondaryBackground: '#dfe6f8',
      menuBackground: '#dfe6f8',
      menuTextColor: '#1446ce',
      // accentColor1: string;
      // accentColor2: string;
      // accentColor3: string;
      // accentColor4: string;
      // accentColor5: string;
      // accentColor6: string;
    },
  },
};

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  private themeSubject = new BehaviorSubject<Theme>(defaultTheme);
  private indexRouteSubject = new BehaviorSubject<string>('/');

  theme$ = this.themeSubject.asObservable();
  indexRoute$ = this.indexRouteSubject.asObservable();

  constructor(private storageService: StorageService) {}

  updateTheme(theme: Theme) {
    this.themeSubject.next(theme);
    this.saveTheme(theme);
  }

  saveTheme(theme: Theme) {
    localStorage.setItem('theme', JSON.stringify(theme));
  }

  setTheme(theme: Theme) {
    localStorage.setItem('theme', JSON.stringify(theme));
    this.themeSubject.next(theme);
    this.indexRouteSubject.next(theme?.auth?.tenantId);
  }

  loadTheme() {
    const storedTheme = this.storageService.retrieve('theme');
    if (storedTheme) {
      this.themeSubject.next(storedTheme as Theme);
    }
  }

  getTheme(tenantId: string): Promise<any> {
    let env = environment.name.toLowerCase();
    if (env === 'testing') {
      env = 'development';
    }
    const url = `https://assets.agridence.com/configs/${env}/${tenantId}/config.json`;

    return fetch(url)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        return data; // Handle the JSON data here
      })
      .catch((error) => {
        console.error('There was a problem with the fetch operation:', error);
        throw error;
      });
  }

  getThemeLogoutLink() {
    const tenantId = this.storageService.retrieve(AppStorage.tenantId);

    if (tenantId) {
      return `/org/${tenantId}/login`;
    } else {
      return `/login`;
    }
  }
}
