import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { Injector } from '@angular/core';
import { Router } from '@angular/router';
import { ThemeService } from 'src/app/services/theme.service';
import { Message } from '../message';
import { StorageService } from './../../../../services/storage.service';

const exceptionMessages = [
  {
    title: 'Oops! Something went wrong',
    content: `Your login session has timed out.
    Please Login again.`,
    message: 'Invalid token.',
    icon: 'error',
  },
  {
    title: 'Oops! Something went wrong',
    content: `Your login session has timed out.
    Please Login again.`,
    message: 'Invalid token. Expired.',
    icon: 'error',
  },
  {
    title: "You've been signed out",
    content: 'FORCED_LOG_OUT',
    message: 'Signed out due to duplicate login.',
    icon: 'warning',
    buttonPrimaryText: 'OK',
    buttonSecondaryText: 'Reset my password',
  },
];
export class UnAuthorizedMessage extends Message {
  getMessage(content: string | HttpErrorResponse): Message {
    const processedMessage = this.processMessage(content as HttpErrorResponse);
    this.color = 'error';
    this.type = HttpStatusCode.Unauthorized;
    this.content = processedMessage.content;
    this.title = processedMessage.title;
    this.icon = processedMessage.icon ?? this.icon;
    this.buttonPrimaryText =
      processedMessage.buttonPrimaryText ?? this.buttonPrimaryText;
    this.buttonSecondaryText =
      processedMessage.buttonSecondaryText ?? this.buttonSecondaryText;
    return this;
  }

  processMessage(error: HttpErrorResponse): Message {
    const message = {} as Message;
    message.title = 'Oops! Something went wrong x';
    message.content = '';
    message.icon = 'error';

    const foundMessage = exceptionMessages.find(
      (exceptionMessage) =>
        exceptionMessage.message?.toLowerCase() ===
        error.error.detail?.toLowerCase(),
    );
    if (foundMessage) {
      message.content = foundMessage.content;
      message.title = foundMessage.title;
      message.icon = foundMessage.icon;
      message.buttonPrimaryText = foundMessage.buttonPrimaryText;
      message.buttonSecondaryText = foundMessage.buttonSecondaryText;
    }
    return message;
  }
  close(injector: Injector): void {
    const router = injector.get(Router);
    const themeService = injector.get(ThemeService);
    const logoutUrl = themeService.getThemeLogoutLink();

    if (router.url === logoutUrl) return;

    const storageService = injector.get(StorageService);
    storageService.clear();

    // TODO KK: find a better way to handle this
    if (router.url === '/continental-gpsnr-form') {
      router.navigate(['/continental']);
    } else if (router.url === '/pirelli-gpsnr-form') {
      router.navigate(['/pirelli']);
    } else {
      router.navigate([logoutUrl]);
    }
  }

  action(injector: Injector): void {
    const router = injector.get(Router);
    const themeService = injector.get(ThemeService);
    const logoutUrl = themeService.getThemeLogoutLink();

    if (router.url === logoutUrl) return;

    const storageService = injector.get(StorageService);
    storageService.clear();

    // TODO KK: find a better way to handle this
    if (router.url === '/continental-gpsnr-form') {
      router.navigate(['/continental'], {
        queryParams: { resetPassword: true },
      });
    } else if (router.url === '/pirelli-gpsnr-form') {
      router.navigate(['/pirelli'], { queryParams: { resetPassword: true } });
    } else {
      router.navigate([logoutUrl], { queryParams: { resetPassword: true } });
    }
  }
}
