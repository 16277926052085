import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, defer, switchMap, throwError } from 'rxjs';
import { Message } from '../modules/exception-handling/message/message';
import { MessageService } from '../modules/exception-handling/message/service/message-service';
import { HTTP_REQUEST_CONTEXT_OPTIONS } from './interceptor-context';
import { environment } from 'src/environments/environment';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(private messageService: MessageService) {}
  /**
   * Pipe any http errors encountered to the handleError method
   * @param request the outgoing http request
   * @param next the next http handler, typically is another interceptor or the final HttpBackend
   * @returns after handling the error, rethrow the error as an observable to be handled by the caller
   */
  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    if (this.isIgnoreUrl(request.url)) {
      return next.handle(request);
    }
    return next.handle(request).pipe(
      catchError((err) => {
        return this.handleError(err, request);
      }),
    );
  }
  /**
   * Ignore any http requests that include assets in the url
   * @param url the url of the http request
   * @returns true if the url includes assets, false otherwise
   */
  private isIgnoreUrl(url: string): boolean {
    return url.includes('assets/') || url.includes(environment.CRD_API);
  }
  /**
   * Handle the HTTP error response by checking its type and dispatching it
   * to the message service
   * @param error the error, currently only handling error of type HttpErrorResponse
   * @param request the outgoing http request
   * @returns rethrow the error as an observable to be handled by the caller
   */
  protected handleError(
    error: Error | HttpErrorResponse,
    request: HttpRequest<any>,
  ): Observable<any> {
    //TODO: Create new interceptor for blob exceptions.
    if (error instanceof HttpErrorResponse && error.error instanceof Blob) {
      return defer(() => error.error.text()).pipe(
        switchMap((text: string) => {
          const messages = JSON.parse(text);
          const message = {} as Message;
          try {
            message.content = messages.join('');
            message.type = error.status;
            this.messageService.show(message);
          } catch (e) {
            // do nothing for now
          }
          return throwError(() => messages);
        }),
      );
    } else if (error instanceof HttpErrorResponse) {
      const httpRequestContextOptions = request.context.get(
        HTTP_REQUEST_CONTEXT_OPTIONS,
      );
      const message = {} as Message;
      message.content = error;
      message.type = error.status;
      message.enableCustomException =
        httpRequestContextOptions.customExceptionEnabled;
      message.disableSound = httpRequestContextOptions.disableSound;
      try {
        this.messageService.show(message);
      } catch (e) {
        // do nothing for now
      }
      return throwError(() => error);
    }
  }
}
