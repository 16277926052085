<div class="arp-app-container" *ngIf="theme$ | async as theme">
  <mat-toolbar class="arp-first-toolbar">
    <button mat-icon-button (click)="sidenav.toggle()">
      <mat-icon>menu</mat-icon>
    </button>
    <span class="home-icon icon-x-large" [routerLink]="['/dashboard']">
      <img
        [src]="theme?.headerLogo"
        alt=""
        class="login-img"
        [ngStyle]="{
          'max-width': '100%'
        }" />
    </span>
    <app-breadcrumb></app-breadcrumb>
    <arp-price-widget
      (expandDisplay)="expandPriceDisplay(priceDisplayContainer, $event)">
    </arp-price-widget>
    <app-notification-dropdown></app-notification-dropdown>
    <app-top-menu></app-top-menu>
  </mat-toolbar>
  <mat-sidenav-container>
    <mat-sidenav #sidenav>
      <app-main-menu></app-main-menu>
    </mat-sidenav>
    <mat-sidenav-content>
      <div class="arp-main-panel-container">
        <mat-toolbar
          class="arp-second-toolbar"
          [class.expanded]="isPriceDisplayExpanded">
          <div class="arp-price-display">
            <button
              mat-icon-button
              class="close-button"
              (click)="minimizePriceDisplay()">
              <mat-icon>close</mat-icon>
            </button>
            <ng-template #displayContainer></ng-template>
          </div>
        </mat-toolbar>
        <div class="arp-feature-container">
          <router-outlet></router-outlet>
        </div>
        <app-chat *ngIf="showChat"></app-chat>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
<notifier-container></notifier-container>
