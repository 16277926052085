import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../guards/auth.guard';
import { LoginGuard } from '../guards/login.guard';
import { NonHcUserComponent } from '../modules/non-hc-user/non-hc-user.component';
import { PasswordSetupComponent } from '../modules/password-setup/password-setup.component';
// Import Containers
import { GPSNRFormComponent } from '../components/gpsnr-form/gpsnr-form.component';
import { NotFoundComponent } from '../components/not-found/not-found.component';
import { DefaultLayoutComponent } from '../containers';
import { ProductionEnvironmentGuard } from '../guards/environment.guard';
import { FeatureGuard } from '../guards/feature.guard';
import { GPSNRFormGuard } from '../guards/gpsnr-form.guard';
import { GPSNRLoginGuard } from '../guards/gpsnr-login.guard';
import { GpsnrPasswordSetupGuard } from '../guards/gpsnr-password-setup.guard';
import { GpsnrPasswordSetupComponent } from '../modules/gpsnr-password-setup/gpsnr-password-setup.component';
import { checkFormBaseURL } from '../utils/checkFormBaseURL';
import { tenantGuard } from '../guards/tenant.guard';
import { emptyGuard } from '../guards/empty.guard';
import { EmptyComponent } from '../modules/transit-module/empty/empty.component';
import { initializeGuard } from '../guards/initialize.guard';

export const routes: Routes = [
  {
    path: 'continental',
    loadChildren: () =>
      import('../modules/gpsnr-login/gpsnr-login.module').then(
        (module) => module.GPSNRLoginModule,
      ),
    canActivate: [GPSNRLoginGuard],
  },
  {
    path: 'pirelli',
    loadChildren: () =>
      import('../modules/gpsnr-login/gpsnr-login.module').then(
        (module) => module.GPSNRLoginModule,
      ),
    canActivate: [GPSNRLoginGuard],
  },
  {
    path: 'continental-gpsnr-form',
    component: GPSNRFormComponent,
    data: {
      breadcrumb: 'Reporting Requirements Form',
    },
    canActivate: [GPSNRFormGuard],
  },
  {
    path: 'pirelli-gpsnr-form',
    component: GPSNRFormComponent,
    data: {
      breadcrumb: 'Reporting Requirements Form',
    },
    canActivate: [GPSNRFormGuard],
  },
  {
    path: 'form/auth/password/reset/confirm/:uid/:token',
    component: GpsnrPasswordSetupComponent,
    canActivate: [GpsnrPasswordSetupGuard],
  },
  {
    matcher: (url) => {
      if (checkFormBaseURL(location.href)) {
        return {
          consumed: url,
        };
      }
      return null;
    },
    component: NotFoundComponent,
  },
  {
    path: 'login',
    loadChildren: () =>
      import('../modules/login/login.module').then(
        (module) => module.LoginModule,
      ),
    canActivate: [tenantGuard, LoginGuard],
  },
  {
    path: 'org/:tenantId/login',
    loadChildren: () =>
      import('../modules/login/login.module').then(
        (module) => module.LoginModule,
      ),
    canActivate: [tenantGuard, LoginGuard],
  },
  {
    path: 'transit',
    loadChildren: () =>
      import('../modules/transit-module/transit.module').then(
        (module) => module.TransitModule,
      ),
  },
  {
    path: 'auth/password/reset/confirm/:uid/:token',
    component: PasswordSetupComponent,
  },
  {
    path: 'verify/:token',
    component: NonHcUserComponent,
  },
  {
    path: '',
    component: DefaultLayoutComponent,
    data: {
      title: 'Home',
      breadcrumb: 'Home',
    },
    canActivate: [initializeGuard],
    children: [
      {
        path: 'dashboard',
        loadChildren: () =>
          import('../modules/dashboard/dashboard.module').then(
            (module) => module.DashboardModule,
          ),
        canActivate: [AuthGuard, FeatureGuard],
      },
      {
        path: 'spot',
        loadChildren: () =>
          import('../modules/spot/spot.module').then((m) => m.SpotModule),
        canActivate: [AuthGuard, FeatureGuard],
        data: {
          breadcrumb: '',
        },
      },
      {
        path: 'contract-hub',
        loadChildren: () =>
          import('../modules/contract-hub/contract-hub.module').then(
            (module) => module.ContractHubModule,
          ),
        canActivate: [AuthGuard, FeatureGuard],
        data: {
          breadcrumb: '',
        },
      },
      {
        path: 'legal-contract',
        loadChildren: () =>
          import('../modules/legal-contract/legal-contract.module').then(
            (module) => module.LegalContractModule,
          ),
        canActivate: [AuthGuard, FeatureGuard],
      },
      {
        path: 'new-ltc',
        loadChildren: () =>
          import('../modules/new-ltc/new-ltc.module').then(
            (module) => module.NewLtcModule,
          ),
        canActivate: [AuthGuard, FeatureGuard],
        data: {
          breadcrumb: '',
        },
      },
      {
        path: 'price-discovery',
        loadChildren: () =>
          import('../modules/price-discovery/price-discovery.module').then(
            (module) => module.PriceDiscoveryModule,
          ),
        canActivate: [AuthGuard, FeatureGuard],
        data: {
          breadcrumb: 'Price Discovery',
        },
      },
      {
        path: 'price-configurator',
        loadChildren: () =>
          import(
            '../modules/price-configurator/price-configurator.module'
          ).then((module) => module.PriceConfiguratorModule),
        canActivate: [AuthGuard, FeatureGuard],
        data: {
          breadcrumb: 'Price Configurator',
        },
      },
      {
        path: 'data',
        loadChildren: () =>
          import('../modules/data/data.module').then(
            (module) => module.DataModule,
          ),
        canActivate: [AuthGuard, FeatureGuard],
        data: {
          breadcrumb: '',
        },
      },
      {
        path: 'profile',
        loadChildren: () =>
          import('../modules/user-profile/user-profile.module').then(
            (module) => module.UserProfileModule,
          ),
        canActivate: [AuthGuard],
        data: {
          breadcrumb: '',
        },
      },
      {
        path: 'support',
        loadChildren: () =>
          import('../modules/support/support.module').then(
            (module) => module.SupportModule,
          ),
        canActivate: [AuthGuard],
        data: {
          breadcrumb: '',
        },
      },
      {
        path: 'invoice',
        loadChildren: () =>
          import('../modules/invoice/invoice.module').then(
            (module) => module.InvoiceModule,
          ),
        canActivate: [AuthGuard],
        data: {
          breadcrumb: '',
        },
      },
      {
        path: 'icons',
        loadChildren: () =>
          import('../hc-icons/hc-icons.module').then(
            (module) => module.HCIconsModule,
          ),
        canActivate: [ProductionEnvironmentGuard],
      },
      {
        // TODO: WK: should show 404 page as catch all
        path: '**',
        component: EmptyComponent,
        canActivate: [emptyGuard],
      },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: false,
      preloadingStrategy: PreloadAllModules,
      initialNavigation: 'enabledBlocking',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}

export const RoutingComponents = [NonHcUserComponent];
